import styled from "styled-components";

export const ParagraphH2 = styled.h2`
  color: #015294;
  font-family: "Ubuntu";
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  margin-bottom: 16px;
`;
export const Paragraph = styled.div`
  color: #2e2e2e;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
`;
