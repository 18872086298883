import { styled, css } from "styled-components";
import {
  breakpoint,
  carouselFadeType,
  carouselStyleType,
  CarouselWrapperType,
} from "./CarouselTypes";

export const GenerateSlideBreakpoints = (breakpoints: breakpoint[]) => {
  breakpoints.sort(
    (a: breakpoint, b: breakpoint) => a.breakAtPixels - b.breakAtPixels
  );
  const breakpointStrings: string[] = breakpoints.map(
    (curBreakpoint: breakpoint) =>
      `@media (${curBreakpoint.breakpointType}-width: ${curBreakpoint.breakAtPixels}px){flex: 0 0 ${generateSlideWidth(curBreakpoint.slidesPerFrame)}%;} `
  );

  return breakpointStrings;
};

export const GenerateViewPortBreakpoints = (
  breakpoints: breakpoint[],
  maxPageSize?: string,
  gap: string = "0px"
) => {
  if (!maxPageSize) {
    return [];
  }
  breakpoints.sort(
    (a: breakpoint, b: breakpoint) => a.breakAtPixels - b.breakAtPixels
  );
  const breakpointStrings: string[] = breakpoints.map(
    (curBreakpoint: breakpoint) =>
      `@media (${curBreakpoint.breakpointType}-width: ${curBreakpoint.breakAtPixels}px){max-width:${generateWrapperWidth(curBreakpoint.slidesPerFrame, maxPageSize)};} `
  );
  return breakpointStrings;
};

export const generateWrapperWidth = (
  slidesPerFrame: number,
  maxPageSize?: string
) => {
  if (!maxPageSize) {
    return "";
  }
  return `calc(${maxPageSize} * ${slidesPerFrame})`;
};

export const generateSlideWidth = (slidesPerFrame: number) => {
  return 100 / slidesPerFrame;
};

export const CarouselWrapper = styled.div<CarouselWrapperType>`
  ${({ theme, $justifyContent = "unset" }) => css`
    width: 100%;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    position: relative;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-wrap: unset;
      align-items: unset;
      justify-content: ${$justifyContent};
      flex-direction: row;
    }
  `}
`;

export const CarouselViewport = styled.div<carouselStyleType>`
  overflow: hidden;
  max-width: 100%;
  margin-left: auto;
  cursor: grab;
  ${({ $breakpoints, $gap, $maxPageSize }) => {
    return GenerateViewPortBreakpoints($breakpoints, $maxPageSize, $gap).join(
      " "
    );
  }};
`;

export const CarouselContainer = styled.div<carouselStyleType>`
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: ${({ $gap }) => `-${$gap}`};
`;

export const CarouselSlide = styled.div<carouselStyleType>`
  flex: 0 0
    ${({ $slidesPerFrame }) =>
      generateSlideWidth(1 + ($slidesPerFrame % 1)) + "%"};
  min-width: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;

  padding-left: ${({ $gap }) => $gap};
  z-index: 1;
  &:nth-child(1) {
    z-index: 3;
  }
  ${({ $breakpoints }) => GenerateSlideBreakpoints($breakpoints).join(" ")};
`;

export const SlideContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarouselNavagation = styled.div`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 12px;
  flex-wrap: wrap;
  overflow-x: hidden;
  @media (max-width: 768px) {
    display: none;
  }
`;

type CarouselDotProps = {
  $isActive: boolean;
};

export const CarouselDot = styled.button<CarouselDotProps>`
  ${({ $isActive }) => css`
    all: unset;
    cursor: pointer;

    border-radius: 4px;
    height: 4px;
    width: 40px;
    background-color: #99b9d4;

    &:focus-visible {
      outline: 1px solid black;
      outline-offset: 1px;
    }
    ${$isActive &&
    css`
      height: 6px;
      background-color: #004470;
    `}
  `}
`;

export const CarouselFade = styled.div<carouselFadeType>`
  ${({ $showFade }) => ($showFade ? "" : "display:none;")}
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 20px;
  margin-left: -2px;
  left: 0;
  background: rgb(248, 248, 248);
  background: linear-gradient(
    90deg,
    rgb(247 247 247) 20%,
    rgb(248 248 248 / 00%) 100%
  );
`;