import React from "react";
import {
    StyledContainer,
    StyledBannerImage,
    StyledLowerBanner,
    StyledIcon,
    StyledHeader,
    StyledSubHeader,
    StyledLowerBannerInside,
} from "./PromotionalBanner.styles";
import { ReactComponent as RightArrow } from "../../../assets/icons/Right-Arrow-White.svg";

type PromotionalBannerProps = {
    header?: string;
    headerAltText?: string;
    subHeader?: string;
    image: string;
    ariaRole?: string;
    CallBack?: () => void;
};
export const PromotionalBanner: React.FC<PromotionalBannerProps> = ({
    header,
    headerAltText,
    subHeader,
    image,
    ariaRole,
    CallBack,
}) => {
    return (
        <StyledContainer onClick={CallBack} hasCallback={!!CallBack} role={ariaRole}>
            <StyledBannerImage>
                <img src={image} alt={headerAltText} />
            </StyledBannerImage>
            {header && subHeader && (
                <StyledLowerBanner>
                    <StyledLowerBannerInside>
                        <StyledHeader>{header}</StyledHeader>
                        <StyledSubHeader>{subHeader}</StyledSubHeader>
                    </StyledLowerBannerInside>
                    {CallBack && (
                        <StyledIcon>
                            <RightArrow />
                        </StyledIcon>
                    )}
                </StyledLowerBanner>
            )}
        </StyledContainer>
    );
};
