// src/components/VideoCard.tsx
import React from 'react';
import { VideoBlock } from "./Video.styles";
import ReactPlayer from "react-player/lazy";

type VideoUploaderProps = {
  title?: string;
  videoUrl: string | undefined;
  thumbnailUrl?: string;
  maxVideoWidth: number;
};

const VideoUploader: React.FC<VideoUploaderProps> = ({
  title,
  videoUrl,
  thumbnailUrl,
  maxVideoWidth,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        paddingTop: "56.25%" /* 720 / 1280 = 0.5625 */,
      }}
    >
      <ReactPlayer
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          maxWidth: maxVideoWidth,
        }}
        url={videoUrl}
        controls={true}
        height={"100%"}
        width={"100%"}
      />
    </div>
  );
};

export default VideoUploader;