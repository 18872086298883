import { styled, css } from "styled-components";

export const CarouselItemContainer = styled.button<{ isClickable: boolean }>`
  all: unset;
  ${({ isClickable }) => (isClickable ? "cursor: pointer;" : "")}
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px #0f283533;
  text-decoration: none;
  color: #015294 !important;
  margin: 5px;

  &:focus-visible {
    outline: 1px solid black;
    outline-offset: 1px;
  }
`;

export const CarouselItemHeaderContainer = styled.p`
  padding: 12px 24px 12px 24px;
  min-height: 140px;
`;

export const CarouselItemHeader = styled.p`
  color: #015294 !important;
  font-family: "Ubuntu";
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`;

export const CarouselItemSubHeader = styled.p`
  color: #717073 !important;
  font-family: "Open sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 0.5rem;
`;

export const CarouselItemImageContainer = styled.div`
  position: relative;
  display: flex;
`;

export const CarouselItemImageTag = styled.span<{
  $itemType: "awarenessDate" | "webinar" | "usefulResource" | "podcast";
}>`
  position: absolute;
  background: #47b4bc;
  padding: 4px 8px 4px 8px;
  color: white !important;
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans";
  line-height: 24px;
  bottom: 0;
  left: 10%;

  ${({ $itemType }) =>
    $itemType === "awarenessDate" &&
    css`
      background-color: #00a6d7;
    `}

  ${({ $itemType }) =>
    $itemType === "webinar" &&
    css`
      background-color: #e89528;
    `}

    ${({ $itemType }) =>
    $itemType === "usefulResource" &&
    css`
      background-color: #47b4bc;
    `}


    ${({ $itemType }) =>
    $itemType === "podcast" &&
    css`
      background-color: #8cc442;
    `}
`;

export const CarouselItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 120px;
  min-height: 120px;
`;
